<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card class="card" :bordered="false" v-else>
      <!-- 搜索框 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="委托客户">
                <a-input placeholder="请输入委托客户名称" v-model="queryParam.shortName" />
              </a-form-item>
            </a-col>
            <a-col :xs="6">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- 操作按钮 -->
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
      </div>

      <!-- 表单区域 -->
      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
          </template>
        </span>
      </s-table>

      <!-- 新建/编辑框区域 -->
      <a-modal
        :visible="formVisible"
        :confirmLoading="confirmLoading"
        :width="720"
        :maskClosable="false"
        :title="modalFlag === 'create' ? '创建' + modelTitle : '编辑' + modelTitle"
        @cancel="formVisible = false"
        @ok="handleSubmit">
        <a-form-model
          layout="vertical"
          :model="form"
          :rules="rules"
          ref="form">
          <a-row :gutter="20">
            <a-col :span="8">
              <a-form-model-item label="委托客户" prop="shortName">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  v-model="form.shortName"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo">
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="8">
              <a-form-model-item label="联系人姓名">
                <a-input v-model="form.contactName" />
              </a-form-model-item>
            </a-col>

            <a-col :span="8">
              <a-form-model-item label="联系人电话">
                <a-input v-model="form.contactPhone" />
              </a-form-model-item>
            </a-col>

            <a-col :span="8">
              <a-form-model-item label="租赁备注">
                <a-textarea v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>

    </a-card>
  </keep-alive>
</template>

<script>
import { STable } from '@/components';
import { getMmsMetaPage, createMmsMetaInfo, updateMmsMetaInfo, deleteMmsMetaInfo } from '@/api/mms';
import { getCustomer } from '@/api/common';
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {},
      customerOps: [],
      fetching: false,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '租户',
          dataIndex: 'short_name',
          width: 300
        },
        {
          title: '联系人姓名',
          dataIndex: 'contact_name',
          width: 200
        },
        {
          title: '联系人电话',
          dataIndex: 'contact_phone',
          width: 200
        },
        {
          title: '租赁备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      moduleName: 'tenant_info',
      loadData: parameter => {
        return getMmsMetaPage(this.moduleName, Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      modalFlag: 'create',
      formVisible: false,
      confirmLoading: false,
      form: {
        id: '',
        shortName: {
          key: '',
          label: ''
        },
        contactName: '',
        contactPhone: '',
        remark: ''
      },
      rules: {
        shortName: [{ required: true, message: '请选择租户简称', trigger: 'blur' }]
      },
      modelTitle: '租户管理'
    }
  },
  methods: {
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 3,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.form.shortName = undefined;
        this.queryParam['auth_agency'] = null;
      } else {
        // 委托客户选择框中有值
        this.form.shortName = value;
        this.queryParam['auth_agency'] = value.key;
      }
      this.customerOps = []
      this.fetching = false
    },
    handleCreate() {
      this.modalFlag = 'create';
      this.formVisible = true;
    },
    handleEdit(record) {
      this.modalFlag = 'update';
      this.formVisible = true;
      // 表单赋值操作
      this.form.shortName.key = record.customer_id;
      this.form.shortName.label = record.short_name;
      this.form.contactName = record.contact_name;
      this.form.contactPhone = record.contact_phone;
      this.form.remark = record.remark;
      this.form.id = record.id;
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            'customer_id': this.form.shortName.key,
            'short_name': this.form.shortName.label,
            'contact_name': this.form.contactName,
            'contact_phone': this.form.contactPhone,
            'remark': this.form.remark
          };
          let func = createMmsMetaInfo;
          if (this.modalFlag === 'update') {
            func = updateMmsMetaInfo;
            data['id'] = this.form.id;
          }
          func(this.moduleName, data).then(_ => {
            this.confirmLoading = false;
            this.formVisible = false;
            this.$notification['success']({
              message: '提示',
              description: this.modalFlag === 'update' ? '编辑成功' : '创建成功'
            });
            this.$refs.table.refresh(this.modalFlag === 'create')
          }).catch(_ => {
            this.confirmLoading = false
          });
        } else {
          return false;
        }
      });
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确认要删除?',
        onOk: () => {
          const _ids = flag === 'all' ? this.selectedRowKeys : [record.id];
          const data = { ids: _ids }
          deleteMmsMetaInfo(this.moduleName, data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功'
            });
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true);
          });
        }
      });
    }
  }
}
</script>
